export const createSocketConnection = () => {
    return {
        type: 'CONNECT_SOCKET'
    }
}

export const setNewMarker = (newMarker) => {
    return {
        type: 'SET_MARK',
        newMarker
    }
}

export const setMarkers = (markers) => {
    return {
        type: 'SET_MARKERS',
        markers
    }
}

export const setSession = (session) => {
    return {
        type: 'SET_SESSION',
        session
    }
}

export const setPlaceTypes = (placeTypes) => {
    return {
        type: 'SET_PLACETYPES',
        placeTypes
    }
}