import React, { useState, useCallback } from 'react'
import {Form, Button} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { fetchApi } from '../fetchApi'

const NewMarker = ({ position, setAddingNewMarker }) => {
    const placeTypes = useSelector(state => state.placeTypes)

    const [nombreUbicacion, setNombreUbicacion] = useState('')
    const [tipoUbicacionID, setTipoUbicacionID] = useState(0)

    const handleAddMarker = useCallback((e) => {
        e.preventDefault()
        fetchApi('/add-marker', {
            method: 'POST',
            data: {
                position,
                nombreUbicacion,
                tipoUbicacionID
            }
        })
        setAddingNewMarker(false)
    }, [
        fetchApi,
        position,
        nombreUbicacion,
        tipoUbicacionID,
        setAddingNewMarker
    ])

    return (
        <div
            style={{
                padding: '10px',
                borderRadius: '5px',
            }}
        >
            <Form
                style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItem: 'center'
                }}
                onSubmit={handleAddMarker}
            >
                <input type='hidden' name='position' value={JSON.stringify(position)}></input>
                <Form.Group>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Nombre'
                        name='nombreUbicacion'
                        required
                        value={nombreUbicacion}
                        onChange={(e) => setNombreUbicacion(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Tipo</Form.Label>
                    <Form.Control
                        as='select'
                        name='tipoUbicacionID'
                        value={tipoUbicacionID}
                        onChange={(e) => setTipoUbicacionID(e.target.value)}
                        required
                    >
                        <option disabled selected>Selecciona una opción</option>
                        {
                            placeTypes.map((e, i) => {
                                return (
                                    <option value={e._id}>{e.tipo}</option>
                                )
                            })
                        }
                    </Form.Control>
                </Form.Group>
                <Button variant="outline-dark" size='sm' type="submit" disabled={!tipoUbicacionID || !nombreUbicacion}>
                    Agregar
                </Button>
            </Form>
        </div>
    )
}

export default NewMarker
