export const API_SERVER = {
    development: 'http://localhost:3001',
    production: 'https://api.equitel.oces.dev'
}

export const fetchApi = async (url, {
    method = 'GET',
    data = null,
    stringify = true
} = {}) => {
    let response;
    const authed = localStorage.getItem('Authentication')
    await fetch(`${API_SERVER[process.env.NODE_ENV]}${url}`, {
        method,
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
            'Authentication': authed ? `Bearer ${authed}` : null
        },
        credentials: 'include',
        body: method !== 'GET' ? (
            stringify ? JSON.stringify(data) : data
        ) : undefined
    }).then((response) => {
        if (response.ok) {
            return response.json()
        } else {
            throw new Error(`Error fetching ${url}`)
        }
    }).then((responseJson) => {
        response = responseJson
    }).catch((error) => {
        console.error(error)
    })
    if (response) return response
}