import React from 'react'
import { NavLink } from 'react-router-dom'
import '../../css/navbar.css'
import { GiInkSwirl } from 'react-icons/gi'
import { useSelector } from 'react-redux'

export const NavBar = () => {
    const session = useSelector(state => state.session)

    const activeStyle = {
        fontWeight: 'bold',
        textDecoration: 'underline'
    }

    return (
        <div className='navbar-custom'>
            <div className='nav-base nav-left'>
                <ul>
                    <li><NavLink exact to='/' activeStyle={activeStyle}>Inicio</NavLink></li>
                </ul>
            </div>
            <div className='nav-base nav-center'>
                <NavLink exact to='/'>
                    <GiInkSwirl className='logo'/>
                </NavLink>
            </div>
            <div className='nav-base nav-right'>
                <ul>
                    {
                        session[0] ?
                            <>
                                <li>
                                    <div style={{color: '#fff', margin: 'auto 0px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <span>Hola, {session[1].name.split(' ')[0]}</span>
                                    </div>
                                </li>
                                <li><a href='/signout'>Sign out</a></li>
                            </>
                        :
                        <>
                            <li><NavLink to='/login' activeStyle={activeStyle} >Iniciar Sesión</NavLink></li>
                            <li><NavLink to='/register' activeStyle={activeStyle} >Registrarse</NavLink></li>
                        </>
                    }
                </ul>
            </div>            
        </div>
    )
}
