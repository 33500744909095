import React, { useState, useEffect, useCallback } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { IoMdFingerPrint } from 'react-icons/io'
import { fetchApi } from '../../../fetchApi'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { setSession } from '../../../redux/actions'

const rank = {
    TOO_SHORT: 0,
    WEAK: 1,
    MEDIUM: 2,
    STRONG: 3,
    VERY_STRONG: 4
}

function rankPassword(password) {
    var upper = /[A-Z]/,
        lower = /[a-z]/,
        number = /[0-9]/,
        special = /[^A-Za-z0-9]/,
        minLength = 8,
        score = 0;

    if (password.length < minLength) {
        return rank.TOO_SHORT; // End early
    }

    // Increment the score for each of these conditions
    if (upper.test(password)) score++;
    if (lower.test(password)) score++;
    if (number.test(password)) score++;
    if (special.test(password)) score++;

    // Penalize if there aren't at least three char types
    if (score < 3) score--;

    if (password.length > minLength) {
        // Increment the score for every 2 chars longer than the minimum
        score += Math.floor((password.length - minLength) / 2);
    }

    // Return a ranking based on the calculated score
    if (score < 3) return rank.WEAK; // score is 2 or lower
    if (score < 4) return rank.MEDIUM; // score is 3
    if (score < 6) return rank.STRONG; // score is 4 or 5
    return rank.VERY_STRONG; // score is 6 or higher
}

export const Register = () => {
    const dispatch = useDispatch()

    const passwordLevels = ["muy corta", "débil", "mediana", "buena", "muy buena"]
    const [username, setUsername] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [passwordRank, setPasswordRank] = useState(0)

    const history = useHistory()

    useEffect(() => {
        setPasswordRank(rankPassword(password))
    }, [password])

    const handleRegister = useCallback((e) => {
        e.preventDefault()
        fetchApi('/register', {
            method: 'POST',
            data: {
                username,
                password,
                name
            }
        }).then(res => {
            dispatch(setSession(res))
            if(res.loggedIn) {
                history.push('/')
            }
            else history.push('/register')
        })
    }, [
        fetchApi,
        username,
        password,
        name,
        history
    ])

    return (
        <div
            className='login-container'
        >
            <div
                className='login-prompt'
            >
                <IoMdFingerPrint className='logo' />
                <Form onSubmit={handleRegister}>
                    <Form.Group>
                        <Form.Control
                            type='text'
                            placeholder='usuario'
                            name='username'
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value)
                            }}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            type='text'
                            placeholder='nombre'
                            name='name'
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            type='password'
                            placeholder='contraseña'
                            name='password'
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                            disabled={(!username)}
                            required
                        />
                        {
                            password
                            ? <div
                                    className={passwordRank >= 2 ? ( passwordRank === 2 ? "text-warning" : "text-success" ): "text-danger"}
                                    style={{textAlign: 'center'}}
                                >
                                <small>
                                    Tu contraseña es {passwordLevels[passwordRank]}.
                                </small>
                            </div>
                            : null
                        }
                    </Form.Group>
                    {
                        password && passwordRank >= 2 ?
                        <Form.Group>
                            <Form.Control
                                type='password'
                                placeholder='password confirm'
                                onChange={(e) => {
                                    setPasswordConfirm(e.target.value)
                                }}
                                required
                            />
                            {
                                passwordConfirm ?
                                <div
                                        className={password === passwordConfirm ?  "text-success" : "text-danger"}
                                        style={{textAlign: 'center'}}
                                >
                                    <small>
                                        {password === passwordConfirm ? 'Las contraseñas coinciden.' : 'Las contraseñas no coinciden.'}
                                    </small>
                                </div>
                                : null
                            }
                        </Form.Group>
                        :
                        null
                    }
                    <Button
                        className='submit-button'
                        variant="outline-light"
                        type="submit"
                        disabled={!(username && name && password && passwordRank >= 2 && password === passwordConfirm)}
                    >
                        <AiOutlineArrowRight/>
                    </Button>
                </Form>
            </div>
        </div>
    )
}
