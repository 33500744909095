import React from 'react'
import Mapa from '../Mapa'
import {useSelector} from 'react-redux'
import { NavLink } from 'react-router-dom'

export const Home = () => {
    const session = useSelector(state => state.session)
    return (
        <div>
            {
                session[0] ?
                (
                    <Mapa></Mapa>
                )
                :
                (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            color: '#fff',
                            width: '100%',
                            height: 'calc(100vh - 4rem)',
                            padding: '15%'
                        }}
                    >
                        <h1 style={{fontWeight: '700'}}>Debes<br/><NavLink to='/login'>iniciar sesión</NavLink> / <NavLink to='/register'>registrarte</NavLink><br/>para poder usar esta aplicación.</h1>
                    </div>
                )
            }
            
        </div>
    )
}
