const sessionReducer = (state = [false, {}], action) => {
    switch(action.type){
        case 'SET_SESSION':
            if (action.session?.loggedIn){
                return [
                    true, 
                    {
                        name: action.session.name,
                        id: action.session.id
                    }
                ]
            }
            return [false, {}]
        default:
            return state
    }
}

export default sessionReducer