import { combineReducers } from 'redux'
import markersReducer from './markersReducer'
import placeTypesReducer from './placeTypesReducer'
import sessionReducer from './sessionReducer'
import socketReducer from './socketReducer'

const rootReducer = combineReducers({
    socket: socketReducer,
    markers: markersReducer,
    session: sessionReducer,
    placeTypes: placeTypesReducer
})

export default rootReducer