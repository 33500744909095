import React, { useState, useEffect, useCallback } from 'react'
import {Form, Button} from 'react-bootstrap'
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import {useSelector} from 'react-redux'
import { fetchApi } from '../fetchApi'

const getPromedioCalificaciones = (calificaciones) => {
    if (!calificaciones) return 0
    let sum = 0;
    for ( let i = 0; i < calificaciones.length; i++ ){
        sum += calificaciones[i].score
    }
    return sum/calificaciones.length
}

const starStyle = {
    color: '#FDCC0D',
    filter: 'drop-shadow(1px 1px rgba(0,0,0,0.5))',
    margin: '0px 2px'
}

const MarkerInfo = ({setAddingNewMarker, ...props}) => {
    const session = useSelector(state => state.session)

    const placeTypes = useSelector(state => state.placeTypes)

    const [mousePos, setMousePos] = useState(0)

    const [addingComment, setAddingComment] = useState(false)

    const [mousePosFixed, setMousePosFixed] = useState(false)

    const [showAllComments, setShowAllComments] = useState(false)

    const [calificacionPromedio, setCalificacionPromedio] = useState(0)

    const [selfCommentInMark, setSelfCommentInMark] = useState(false)

    const [selfComment, setSelfComment] = useState({})

    const [calificaciones, setCalificaciones] = useState([])

    useEffect(() => {
        setCalificacionPromedio(getPromedioCalificaciones(props.markerSelected.calificaciones))
        let commentIndex = props.markerSelected.calificaciones.findIndex(e => e.byID === session[1].id)
        if(commentIndex !== -1){
            setSelfCommentInMark(true)
            setSelfComment(props.markerSelected.calificaciones[commentIndex])
        } else {
            setSelfCommentInMark(false)
            setSelfComment({})

        }
        setCalificaciones(props.markerSelected.calificaciones.sort((a, b) => a.timestamp > b.timestamp ? -1 : 1))
    }, [props.markerSelected])

    const onDeleteMarker = useCallback((e) => {
        e.preventDefault()
        fetchApi('/delete-marker', {
            method: 'POST',
            data: {
                markerID: props.markerSelected._id
            }
        })
        setAddingNewMarker(false)
    }, [fetchApi, props.markerSelected, setAddingNewMarker])

    const handleComment = useCallback((e) => {
        e.preventDefault()
        fetchApi(selfCommentInMark ? '/edit-comment' : '/add-comment', {
            method: 'POST',
            data: {
                score: mousePos,
                markerID: props.markerSelected._id,
                comment: selfComment.comment
            }
        })
    }, [
        fetchApi,
        mousePos,
        props.markerSelected,
        selfComment
    ])

    const handleDeleteComment = useCallback((e) => {
        e.preventDefault()
        fetchApi('/delete-comment', {
            method: 'POST',
            data: {
                markerID: props.markerSelected._id
            }
        })
    }, [
        fetchApi,
        props.markerSelected,
    ])

    return (
        <div
            style={{
                display: 'inline-flex',
                flexDirection: 'column',
                minWidth: '10rem',
                position: 'relative',
                alignItems: 'center',
                padding: '10px'
            }}
        >
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: '10px',
                    borderBottom: '1px solid rgba(0,0,0,0.2)',
                    padding: '10px 0px'
                }}
            >
                <span
                    style={{
                        fontWeight: '500',
                        fontSize: '1.5rem',
                        textTransform: 'capitalize',
                        textAlign: 'center'
                    }}
                >
                    {props.markerSelected.nombre}
                </span>
                <small
                    style={{
                        textTransform: 'capitalize',
                        textAlign: 'center'
                    }}
                >
                    {props.markerSelected.tipoID && placeTypes.find(placeType => placeType._id === props.markerSelected.tipoID).tipo}
                </small>
                <div
                    style={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}
                >
                    {
                        [1,2,3,4,5].map((e, i) => {
                            return (
                                calificacionPromedio >= e ?
                                <BsStarFill key={i} style={starStyle}/>
                                :
                                (
                                    calificacionPromedio < e && calificacionPromedio >= e - 0.5 ?
                                    <BsStarHalf key={i} style={starStyle}/>
                                    :
                                    <BsStar key={i} style={starStyle}/>
                                )
                            )
                        })
                    }
                </div>
                {   
                    session[1].id === props.markerSelected.by ?
                    (
                        <Form
                            style={{
                                display: 'flex',
                                justifyContent: 'center', 
                                alignItems: 'center',
                                padding: '10px'
                            }}
                            onSubmit={onDeleteMarker}
                        >
                            <input type="hidden" value={props.markerSelected._id} name='markerID'/>
                            <Button variant='outline-danger' size='sm' type='submit'><MdDelete/> Eliminar</Button>
                        </Form>
                    )
                    :
                    (
                        ''
                    )
                    
                }
            </div>
            <div
                style={{
                    maxHeight: '33vh',
                    overflow: 'auto',
                    display: 'inline-flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: '10px',
                    borderBottom: '1px solid rgba(0,0,0,0.2)',
                    padding: '10px 0px',
                    alignItems: 'center'
                }}
            >
                {
                    calificaciones.slice(0, showAllComments ? props.markerSelected.calificaciones.length : 4).map((e, i) => {
                        return (
                            <div
                                style={{
                                    maxWidth: '10rem',
                                    display: 'inline-flex',
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                    marginBottom: '5px',
                                }}
                                key={i}
                            >
                                <span
                                    style={{
                                        fontWeight: '500',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '0.8rem'
                                    }}
                                >
                                    {e.by} · {e.score} <BsStarFill style={starStyle}/>
                                </span>
                                <span
                                    style={{
                                        fontSize: '0.7rem',
                                        maxWidth: '100%'
                                    }}
                                >
                                    {
                                        e.comment.length > 50 ?
                                        `${e.comment.slice(0,50)}...`
                                        :
                                        e.comment
                                    }
                                </span>
                            </div>
                        )
                    })
                }
                {
                    props.markerSelected.calificaciones.length > 4 ?
                    (
                        !showAllComments ?
                        (
                            <Button
                                variant='link'
                                size='sm'
                                onClick={() => {
                                    setShowAllComments(true)
                                }}
                            >
                                Mostrar más
                            </Button>
                        )
                        : 
                        (
                            <Button
                                variant='link'
                                size='sm'
                                onClick={() => {
                                    setShowAllComments(false)
                                }}
                            >
                                Mostrar menos
                            </Button>
                        )
                    )
                    :
                    (
                        null
                    )
                }
            </div>
            <div>
                {
                    addingComment || selfCommentInMark ?
                    (
                        <Form
                            style={{
                                display: 'inline-flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItem: 'center'
                            }}
                            onSubmit={handleComment}
                        >
                            <input type="hidden" value={mousePos} name='score' />
                            <input type="hidden" value={props.markerSelected._id} name='markerID' />
                            <Form.Group
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                onMouseLeave={() => {
                                    if (!mousePosFixed)
                                    setMousePos(0)
                                }}
                            >
                                {
                                    [1,2,3,4,5].map((e, i) => {
                                        return (
                                            mousePos >= e ?
                                            <BsStarFill
                                                key={i}
                                                className='interative-start'
                                                onMouseEnter={() => {
                                                    if (!mousePosFixed)
                                                    setMousePos(e)
                                                }}
                                                onClick={() => {
                                                    setMousePosFixed(!mousePosFixed)
                                                    setMousePos(e)
                                                }}
                                            />
                                            :
                                            <BsStar
                                                key={i}
                                                className='interative-start'
                                                onMouseEnter={() => {
                                                    if (!mousePosFixed)
                                                    setMousePos(e)
                                                }}
                                                onClick={() => {
                                                    setMousePosFixed(!mousePosFixed)
                                                    setMousePos(e)
                                                }}
                                            />
                                        )
                                    })
                                }
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{selfCommentInMark ? 'Modifica tu comentario' : 'Añade un comentario'}</Form.Label>
                                <Form.Control
                                    as='textarea'
                                    rows={3}
                                    style={{
                                        textAlign: 'left'
                                    }}
                                    value={selfComment.comment}
                                    onChange={(e) => {
                                        setSelfComment({...selfComment, comment: e.target.value})
                                    }}
                                    name='comment'
                                >
                                </Form.Control>
                            </Form.Group>
                            <Button
                                variant='outline-dark'
                                size='sm'
                                type='submit'
                                disabled={!mousePosFixed}
                            >
                                {selfCommentInMark ? 'Modificar' : 'Enviar'}
                            </Button>
                            {
                                selfCommentInMark ?
                                (
                                    <Form
                                        style={{
                                            display: 'inline-flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItem: 'center'
                                        }}
                                        onSubmit={handleDeleteComment}
                                    >
                                        <input type="hidden" value={props.markerSelected._id} name='markerID' />
                                        <Button
                                            variant='outline-danger'
                                            size='sm'
                                            type='submit'
                                        >
                                            Eliminar
                                        </Button>
                                    </Form>
                                )
                                :
                                (
                                    <></>
                                )
                            }
                        </Form>
                    )
                    :
                    (
                        <Button
                            variant='outline-dark'
                            size='sm'
                            onClick={() => {
                                setAddingComment(true)
                            }}
                        >
                            Añadir un comentario
                        </Button>
                    )
                }                            
            </div>
        </div>
    )
}

export default MarkerInfo
