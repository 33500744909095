import React, {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GoogleMap, Marker, useJsApiLoader, InfoWindow } from '@react-google-maps/api';
import NewMarker from './NewMarker'
import MarkerInfo from './MarkerInfo'
import { setNewMarker, setMarkers, setPlaceTypes } from '../redux/actions'
import { fetchApi } from '../fetchApi';

const containerStyle = {
    width: '100vw',
    height: 'calc(100vh - 4rem)'
}

const Mapa = () => {
    const dispatch = useDispatch()

    const mapRef = useRef()

    const socket = useSelector(state => state.socket)

    const markers = useSelector(state => state.markers)

    const placeTypes = useSelector(state => state.placeTypes)

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCbNm0ixR4p7pxfd4GoP1K9Cc3eDDyoJjE",
        language: 'es'
    })

    const [position, setPosition] = useState({
        lat: 7.1193,
        lng: -73.122
    })

    const [zoom, setZoom] = useState(13)

    const [addingNewMarker, setAddingNewMarker] = useState(false)
    const [markerClicked, setMarkerClicked] = useState(false)
    const [markerSelected, setMarkerSelected] = useState({
        _id: '',
        nombre: '',
        tipoID: '',
        position: {},
        calificaciones: []
    })

    const [infoPos, setInfoPos] = useState({
        lat: 0,
        lng: 0
    })

    useEffect(() => {
        fetchApi('/api/get-markers')
            .then(data => dispatch(setMarkers(data)))

        socket.on('newMarker', (marker) => {
            dispatch(setNewMarker(marker))
        })

        socket.on ('commentChanged', (newMarkers) => {
            dispatch(setMarkers(newMarkers))
        })

        socket.on('placeDeleted', () => {
            fetchApi('/api/get-markers')
                .then(data => dispatch(setMarkers(data)))
        })
    }, [])

    useEffect(() => {
        if (markerSelected._id){
            let markIndex = markers.findIndex(e => e._id === markerSelected._id)
            if (markIndex === -1){
                setMarkerClicked(false)
            } else {
                setMarkerSelected(markers[markIndex])
            }
        }
    }, [markers])

    const mapDespl = .0025

    const onLoad = (map) => {
        mapRef.current = map;
    }

    const onClick = (event) => {
        let latLng = event.latLng
        let newMarkerPos = {
            lat: latLng.lat(),
            lng: latLng.lng()
        }
        setMarkerClicked(false)
        setPosition(newMarkerPos)
        setInfoPos(newMarkerPos)
        setAddingNewMarker(true)
        setZoom(17)
    }

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={position}
            zoom={zoom}
            onLoad={onLoad}
            onClick={onClick}
            onDragEnd={() => {
                if (!mapRef.current) return;

                const newPos = mapRef.current.getCenter().toJSON()
                setPosition(newPos)
            }}
            onZoomChanged={() => {
                if (!mapRef.current) return;

                setZoom(mapRef.current.zoom)
            }}
        >
            {
                markers.map((e, i) => {
                    let placeType = placeTypes.find(p => p._id === e.tipoID)
                    return (
                        <Marker
                            position={e.position}
                            key={i}
                            onClick={() => {
                                setAddingNewMarker(false)
                                setPosition({lat:e.position.lat+mapDespl, lng:e.position.lng})
                                setInfoPos(e.position)
                                setMarkerClicked(true)
                                setMarkerSelected(e)
                                setZoom(17)
                            }}
                            icon={{
                                url: placeType && placeType.icon,
                                size: new window.google.maps.Size(48, 48),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(17, 34),
                                scaledSize: new window.google.maps.Size(25, 25)
                            }}
                        />
                    )
                })
            }
            {
                addingNewMarker ?
                <InfoWindow
                    position={infoPos}
                    onCloseClick={() => {
                        setAddingNewMarker(false)
                    }}
                    setAddingNewMarker={setAddingNewMarker}
                >
                    <NewMarker position={infoPos} setAddingNewMarker={setAddingNewMarker}/>
                </InfoWindow>
                :
                null
            }
            {
                markerClicked ?
                <InfoWindow
                    position={infoPos}
                    onCloseClick={() => {
                        setMarkerClicked(false)
                    }}
                    onUnmount={() => {
                        setMarkerClicked(false)
                    }}
                >
                    <MarkerInfo markerSelected={markerSelected}/>
                </InfoWindow>
                :
                <></>
            }
        </GoogleMap>
    ) : <></>
}

export default React.memo(Mapa)
