import React, { useCallback, useState } from 'react'
import '../../../css/login.css'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { BsFillLockFill } from 'react-icons/bs'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { fetchApi } from '../../../fetchApi'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { setSession } from '../../../redux/actions'

export const Login = () => {
    const dispatch = useDispatch()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const history = useHistory()

    const handleLogin = useCallback((e) => {
        e.preventDefault()
        fetchApi('/login', {
            method: 'POST',
            data: {
                username,
                password
            }
        }).then(res => {
            dispatch(setSession(res))
            if(res.loggedIn) {
                history.push('/')
            }
            else history.push('/login')
        })
    }, [
        fetchApi,
        username,
        password
    ])

    return (
        <div
            className='login-container'
        >
            <div
                className='login-prompt'
            >
                <BsFillLockFill className='logo' />
                <Form onSubmit={handleLogin}>
                    <Form.Group>
                        <Form.Control
                            type='text'
                            placeholder='usuario'
                            name='username'
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value)
                            }}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            type='password'
                            placeholder='contraseña'
                            name='password'
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                            disabled={(!username)}
                            required
                        />
                    </Form.Group>
                    <Button
                        className='submit-button'
                        variant="outline-light"
                        type="submit"
                        disabled={!(username && password)}
                    >
                        <AiOutlineArrowRight/>
                    </Button>
                </Form>
            </div>
        </div>
    )
}
