const markersReducer = (state = [], action) => {
    switch(action.type){
        case 'SET_MARK':
                return [...state, action.newMarker]
        case 'SET_MARKERS':
            return action.markers
        default:
            return state
    }
}

export default markersReducer