import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/index.css'
import Spinner from './custom/Spinner'
import { GiInkSwirl } from 'react-icons/gi'
import * as routes from './routes'
import { createSocketConnection, setSession, setPlaceTypes } from '../redux/actions'
import { fetchApi } from '../fetchApi'


const App = () => {
  const dispatch = useDispatch()

  const location = useLocation()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [backgroundStyle, setBackgroundStyle] = useState({
    width:'100%',
    height:'100%',
    objectFit: 'cover',
    filter: 'blur(20px)',
    opacity: '.8',
    transition: 'filter 2s'
  })

  useEffect(() => {
    dispatch(createSocketConnection())
    fetchApi('/api/get-session')
        .then(data => dispatch(setSession(data)))
        .then(() => {
          setLoading(false)
          setBackgroundStyle({...backgroundStyle, filter: 'blur(5px)'})
        })
        
    fetchApi('/api/get-placetypes')
      .then(data => dispatch(setPlaceTypes(data)))

    setTimeout(() => {
      setLoading(false)
    }, 3000)

  }, [])

  useEffect(() => {
    setBackgroundStyle({...backgroundStyle, filter: 'blur(5px)'})
  }, [loading])

  useEffect(() => {
    if (location.pathname === '/signout') {
      fetchApi('/signout')
        .then(() => dispatch(setSession(null)))
      history.push('/')
    }
  }, [location, history])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <div
        style={{
          position: 'absolute',
          width: '100%', 
          height: '100%',
          zIndex: -1
        }}
      >
        <img 
          src='https://unsplash.com/photos/YO1WPCUtvR4/download?force=true&w=1920'
          style={backgroundStyle}
          alt='background'
        />
      </div>
      {
        loading ? 
          <div
            style={{
              position: 'absolute',
              display: 'inline-flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              width: '100%',
              height: '100%',
              color: '#fff'
            }}
          >
            <Spinner animation='border' variant='light'>
              <GiInkSwirl/>
            </Spinner>
            <span>Cargando...</span>
          </div>
        :
        <></>
      }
      <div
        style={{
          opacity: loading ? '0' : '1',
          transition: 'opacity .5s'
        }}
      >
        <routes.NavBar/>
        <div className='content'>
          <Switch>
            <Route exact path='/' >
              <routes.Home />
            </Route>
            <Route path='/login' >
                <routes.Login />
            </Route>
            <Route path='/register' >
                <routes.Register />
            </Route>
          </Switch>
        </div>
      </div>
    </>
    
  )
}

export default App
