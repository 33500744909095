import React from 'react'

const Spinner = (props) => {
    return (
        <div
            style={{
                animation: 'rotate-counter-clockwise 1s infinite',
                fontSize: '5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#fff'
            }}
        >
            {props.children}
        </div>
    )
}

export default Spinner
