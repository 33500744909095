import socketIOClient from "socket.io-client";
import { API_SERVER } from "../../fetchApi";

const socketReducer = (state = null, action) => {
    switch(action.type){
        case 'CONNECT_SOCKET':
            return socketIOClient(API_SERVER[process.env.NODE_ENV])
        default:
            return state
    }
}

export default socketReducer